//========================
// define base colors
//========================
// dont set this to a color that can be interpreted as danger or warning
$primary: #348E94 !default;
$secondary: #093055 !default;
$dark: #292929 !default;
$light: #E5EAEF !default;

//========================
// define theme colors
//========================
$theme-1: $primary !default;
$theme-2: #F59E3A !default;
$theme-3: $secondary !default;
$theme-4: #E34449 !default;
$theme-5: #A4D8E3 !default;
$theme-colors: (
  'theme-1': $theme-1,
  'theme-2': $theme-2,
  'theme-3': $theme-3,
  'theme-4': $theme-4,
  'theme-5': $theme-5
);
$light-accent: lighten($theme-2, 30%);

//========================
// general theme options
//========================
$footer-header-color: $secondary !default;
$card-link-color: $primary !default;
$link-decoration: none;
$border-radius: 10px; // radius for cards, images and buttons
$line-height-base: 1.65;

//========================
// define flavours
//========================
// Always add preserveAspectRatio="none" to the .svg
$use-header-flavour: true; // flavour-header.svg
$use-footer-flavour: true; // flavour-footer.svg
$use-background-pattern: false; // flavour-pattern.png
$use-background-flavour: true;  // flavour-image.svg

//========================
//define text properties
//========================
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@200;300;400;500;600;800&display=swap');
$font-family-base: 'Geologica', sans-serif;
$headings-font-weight: 900;
$display-font-weight: 400;
$headings-color: $secondary;
$headings-text-transform: none; // default 'none'

//========================
//define button properties
//========================
$btn-text-transform: uppercase; // default 'none'
$btn-font-weight: 300;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-footer-style: theme-2;

//========================
//define banner spots properties
//========================
$accordion-banner-bg-color: theme-1; // Ect: theme-1, primary, white
$accordion-banner-text-color: white;


@import '../../../../src/scss/main.scss';

@import '../../../../src/scss/themes/template-barometer.scss';
