//default fallback variables
$display-font-weight: 600 !default;
$footer-bg-color: $light !default;
$max-flavour-height: 110px !default;

@import '@/src/scss/components/hero-video.scss';

.row--quickbanner {
  max-width:900px;
  @include media-breakpoint-up(md) {
    @if ($use-header-flavour) {
      margin-top: -8rem;
    }
    @else {
      margin-top: -5rem;
    }
  }
}

.flavour--img {
  opacity: 0.2;
  img {
    max-height: 200px;
    @include media-breakpoint-up(md) {
      max-height: 300px;
    }
  }
}

.active {
  border-bottom: solid 2px $theme-2;
}

.rounded-lg {
  border-radius: $border-radius !important;
}

.btn {
  text-transform: $btn-text-transform;
  &.btn--footer {
    @extend .btn-#{$btn-footer-style}
  }
}

.banner-max {
  object-fit: cover;
  min-height: 450px;
  max-height: 550px;
  @include media-breakpoint-down(sm) {
    min-height: 300px;
    max-height: 400px;
  }
}

.section--recommended {
  @include media-breakpoint-down(md) {
    background: none !important;
  }
}

.photo-banner {
  @extend .object-fit-cover;
  @extend .mx-auto;
  @extend .w-100;
  min-height: 350px;
  max-height: 450px;
  @include media-breakpoint-down(sm) {
    min-height: 200px;
    max-height: 300px;
  }
}

.navbar-collapse {
  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }
}

.navbar-brand--img {
  height: 70px;
  @include media-breakpoint-up(lg) {
    height: 106px;
  }
}

.nav-link, .topbar-card--link {
  @extend .text-dark;
  i {
    @extend .text-theme-2;
    @extend .mx-1;
  }
}

.dropdown-submenu {
  .dropdown-menu {
    @extend .p-0;
    border-radius: 0 !important;
  }
}

.topbar-card--link {
  .badge {
    @extend .float-right;
  }
}

.footer-menu--item {
  font-weight: 400 !important;
  text-decoration: underline;
}

.bg-footer--theme {
  background-color: $footer-bg-color !important;
}


.card-title {
  @extend .mb-2;
}

.card--item {
  .info-bar--background {
    @extend .text-theme-1;
    @extend .pb-0;
    @extend .mb-0;
  }
  .card-body {
    @extend .pt-2;
  }
  .card-text {
    @extend .mb-1;
  }
}

.thead-light {
  th {
    background-color: #{$light-accent} !important;
    @extend .border-0;
  }
  tr {
    th:first-child {
      border-radius: $border-radius 0 0 0;
    }
    th:last-child {
      border-radius: 0 $border-radius 0 0;
    }
  }
}

.accordion {
  .card-header, button {
    @extend .bg-#{$accordion-banner-bg-color};
    @extend .text-#{$accordion-banner-text-color};
  }
}

.top-menu {
  @extend .pt-lg-3;
}

.container--menu {
  .navbar-nav {
    .nav-item:active {
      border-bottom: solid 1px $theme-2;
    }
  }
}

.main-headerless {
  @extend .pt-4;
}

.header--border {
  @extend .border;
  @extend .border-light;
}

.wrapper {
  @extend .mt-3;
}

.container--menu {
  @extend .my-3;
  @extend .p-0;
  @extend .my-lg-0;
  @extend .justify-content-end;
  .navbar--menu {
    @extend .p-0;
    @extend .pb-lg-2;
  }
  .shoppingcart--icon {
    @extend .mr-2;
    @extend .text-theme-2;
  }
  .top-menu, ul {
    @extend .m-0;
    @extend .ml-auto;
    @extend .d-flex;
    @extend .pr-lg-2;
    .nav-item {
      @extend .my-auto;
      @extend .ml-2;
      @extend .d-block;
    }
  }
  .nav-search {
    .nav-link {
      @extend .px-1;
    }
  }
}

#rooster {
  @extend .mb-2;
}

.flavor-shape {
  @extend .w-100;
  max-height: $max-flavour-height;
  aspect-ratio: 8.1;
  margin-bottom: -1px;
  margin-top: -1px;
}

.agenda--home {
  h2 {
    @extend .text-center;
    @extend .mb-4;
  }
}

.account-dropdown--style, .language-selector {
  @extend .py-0;
}

.card-stretch-hover {
  .card-img-top {
    width: 100%;
    transition: transform .3s;
    @extend .h-auto;
  }
  &:hover {
    .card-img-top {
      transform: scale(1.1);
    }
  }
}

.display-5 {
  font-weight: $display-font-weight !important;
}

//========================
// Flavours
//========================
@if (not $use-header-flavour) {
  .photo-banner {
    .flavor-shape {
      display: none;
    }
  }
}

@if (not $use-footer-flavour) {
  footer {
    .flavor-shape {
      display: none;
    }
  }
}

@if (not $use-background-flavour) {
  .banner-section {
    .flavour--img {
      display: none;
    }
  }
}

//$use-background-pattern: false; // flavour-pattern.png
